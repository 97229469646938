<template>
<div class="mb-4">
      <Subtitle title="Senaste gästboksinläggen"></Subtitle>
      <Loader v-if="loading" cols="3" rows="3"></Loader>
      <div v-else>
            <Entry v-for="entry in entries" :key="entry.id" :entry="entry"> </Entry>
            <Alert v-if="entries.length <= 0" variant="info" >Inga inlägg i gästboken! Bli först med att skriva!</Alert>
            <div class="text-center mt-2">
                  <router-link class="btn btn-outline-primary" to="/gastbok">Till gästboken</router-link>
            </div>
      </div>
</div>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Loader = () => import("@/components/miscellaneous/Loader");
const Subtitle = () => import("@/components/common/Subtitle");
const Entry = () => import("@/components/gbook/Entry");

export default {
      name: 'DashboardGbook',
      components: {
            Alert,
            Loader,
            Subtitle,
            Entry
      },
      computed: {
            entries() {
                   var data = this.$store.getters["dashboard/dashboard"];
                  if (data && data.gbook)
                        return data.gbook.splice(0,3);
                  return [];
            },
            loading() {
                  return this.$store.getters["dahsboard/loading"];
            },
      }
};
</script>
